import { ItemDaVenda, TipoDeTransacaoDeVenda, TurnoDeVenda, Venda } from '@/models'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { criarDevolucao, obterValorDoItemDevolucao } from '@/shareds/venda-shareds'
import AlterarPrecosDaVendaPorCliente from './AlterarPrecosDaVendaPorCliente'
import { Inject } from 'inversify-props'
import { ModalidadeDeVendaServiceAdapter, VendaServiceAdapter } from '.'
import AlterarPrecosDaNotaPorCliente from './AlterarPrecosDaNotaPorCliente'

type UseCaseParams = {
	vendaModule?: typeof VendaModule
	itensADevolver: ItemDaVenda[]
	vendaOrigem: Venda
	vendaOriginal: Venda
	turnoDeVenda: TurnoDeVenda | null
	cpfOuCnpjDoCliente: string | null
}

type UseCaseParamsSemTurno = {
	vendaModule?: typeof VendaModule
	itensADevolver: ItemDaVenda[]
	vendaOrigem: Venda
	vendaOriginal: Venda
	tipoDeTransacao: TipoDeTransacaoDeVenda
}

export default class IniciarUmaDevolucao {

	@Inject("VendaServiceAdapter")
	private vendaService!: VendaServiceAdapter 

	@Inject("ModalidadeDeVendaServiceAdapter")
	private modalidadeService!: ModalidadeDeVendaServiceAdapter 

	devolver = async ({
		vendaModule = VendaModule,
		itensADevolver,
		vendaOrigem,
		vendaOriginal,
		turnoDeVenda,
		cpfOuCnpjDoCliente,
	}: UseCaseParams): Promise<void> =>{
		if (!itensADevolver.length) throw new Error('Nenhum item selecionado')
		const itensDaDevolucao = itensADevolver.map(item => ({
			...item,
			desconto: {
				isPercentual: false,
				valor: 0,
			},
			preco: obterValorDoItemDevolucao(item, this.obterQuantidadeOriginalDoItem(vendaOrigem, item.id)),
			idItemOrigem: item.idItemOrigem || item.id,
		}))

		let parametrosFiscaisTratado
		if (turnoDeVenda) {
			parametrosFiscaisTratado = turnoDeVenda.pontoDeVenda.parametrosFiscais
		}

		const devolucao = criarDevolucao({
			itens: itensDaDevolucao,
			vendaOrigem,
			vendaOriginal,
			...parametrosFiscaisTratado,
		})
	
		if (turnoDeVenda) {
			devolucao.identificador = await this.vendaService.gerarIdentificadorDeVenda(turnoDeVenda.pontoDeVenda.id)
			devolucao.loja = turnoDeVenda.pontoDeVenda.loja.id
		}

		if (cpfOuCnpjDoCliente !== null) {
			devolucao.cpfDoCliente = cpfOuCnpjDoCliente
		}

		if (vendaModule.lojaDaVenda && vendaOrigem.pontoDeVenda && vendaModule.lojaDaVenda.id !== vendaOrigem.pontoDeVenda.loja.id) {
			devolucao.tipoDeTransacao = 'Outros'
		} else {
			devolucao.tipoDeTransacao = 'Devolução'
		}

		const modalidadesDisponiveis = await this.modalidadeService.find(vendaModule.lojaDaVenda?.id)
		const modalidadeDevolucao = modalidadesDisponiveis.filter(modalidade => modalidade.origem === 'Devolução')
		
		if (modalidadeDevolucao.length > 0) {
			devolucao.modalidadeDeVenda = modalidadeDevolucao[0]
		}

		vendaModule.setVendaAtual(devolucao)
		vendaModule.setInformacoesDoCliente(null)
		await AlterarPrecosDaVendaPorCliente({
			cliente: devolucao.cpfDoCliente,
			forcarReconsulta: true,
			vendaModule,
			validarLimiteDeProduto: false,
		})
		if (!devolucao.cliente) vendaModule.setInformacoesDoCliente(null)
		vendaModule.setRecarregaVenda(true)
	}

	devolverVendaSemTurno = async ({
		vendaModule = VendaModule,
		itensADevolver,
		vendaOrigem,
		vendaOriginal,
		tipoDeTransacao,
	}: UseCaseParamsSemTurno): Promise<void> =>{
		if (!itensADevolver.length) throw new Error('Nenhum item selecionado')

		const itensDaDevolucao = itensADevolver.map(item => ({
			...item,
			desconto: {
				isPercentual: false,
				valor: 0,
			},
			preco: obterValorDoItemDevolucao(item, this.obterQuantidadeOriginalDoItem(vendaOrigem, item.id)),
			idItemOrigem: item.idItemOrigem || item.id,
		}))

		let parametrosFiscaisTratado
	
		const devolucao = criarDevolucao({
			itens: itensDaDevolucao,
			vendaOrigem,
			vendaOriginal,
			tipoDeTransacao,
			...parametrosFiscaisTratado,
		})
		
		if (vendaModule.lojaDaEntrada?.id) {
			devolucao.identificador = await this.vendaService.gerarIdentificadorDeVendaSemTurno(vendaModule.lojaDaEntrada?.id)
			devolucao.loja = vendaModule.lojaDaEntrada.id
		}

		vendaModule.setEmissaoEntradaAtual(devolucao)
		vendaModule.setInformacoesDoClienteDaNota(null)
		await AlterarPrecosDaNotaPorCliente({
			cliente: devolucao.cpfDoCliente,
			forcarReconsulta: true,
			vendaModule,
			validarLimiteDeProduto: false,
		})
		if (!devolucao.cliente) vendaModule.setInformacoesDoClienteDaNota(null)
		vendaModule.setRecarregaNota(true)
	}

	obterQuantidadeOriginalDoItem(vendaOrigem: Venda, id: string) {
		const item = vendaOrigem?.itens.find(item => item.id === id)
		if (!item) return 0
		return item.quantidade
	}
}