import { Cliente, ItemDaVenda, Loja, TipoDeCliente, Venda } from '@/models'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import Axios from 'axios'
import { isValidCPF, isValidCNPJ } from '@brazilian-utils/brazilian-utils'
import { FindClienteUseCase } from '@/usecases'
import { isVestcasa as isVestcasaPadrao } from '@/shareds/utils'
import moment from 'moment'
import { formatDate } from '@/shareds/date/date-utils'
import AplicarTabelaDePrecoUseCase from '../tabela-de-precos/AplicarTabelaDePrecoUseCase'
import { removerFormatacaoDeCnpjOuCpf } from '@/shareds/formatadores'
import axios from 'axios'

type UseCaseParams = {
	vendaModule?: typeof VendaModule
	findClienteUseCase?: FindClienteUseCase
	cliente: Cliente | string | null
	isVestcasa?: boolean
	onAtualizarCarregando?: (carregando: boolean) => void
	forcarReconsulta?: boolean
	validarLimiteDeProduto: boolean
}

export default async ({
	vendaModule = VendaModule,
	findClienteUseCase = new FindClienteUseCase(),
	cliente,
	isVestcasa = isVestcasaPadrao,
	onAtualizarCarregando,
	forcarReconsulta = false,
	validarLimiteDeProduto,
}: UseCaseParams): Promise<void> => {

	const venda: Venda | null = vendaModule.vendaAtual ? JSON.parse(JSON.stringify(vendaModule.vendaAtual)) : null
	if (!venda) throw new Error('Nenhuma venda em andamento')

	const tipoDeClienteAnterior: TipoDeCliente | null = venda.tipoDeCliente

	const loja: Loja | null = vendaModule.lojaDaVenda
	if (!loja) throw new Error('Nenhuma loja selecionada para venda, reabra o caixa')

	const cpfOuCnpjFormatado: string = typeof cliente !== 'string'
		? cliente?.cnpjOuCpf || ''
		: cliente

	const cpfOuCnpj = removerFormatacaoDeCnpjOuCpf(cpfOuCnpjFormatado)
	if (!forcarReconsulta && cpfOuCnpj === venda.cpfDoCliente && vendaModule.informacoesDoCliente) return

	venda.cpfDoCliente = cpfOuCnpj

	vendaModule.setInformacoesDoCliente(null)
	let alertaDeInformacoesDoCliente: InformacoesDoCliente | null = null
	if (!cpfOuCnpj || (!isValidCPF(cpfOuCnpj) && !isValidCNPJ(cpfOuCnpj))) {
		venda.tipoDeCliente = null
		venda.cliente = null
	} else if (isVestcasa) {
		onAtualizarCarregando && onAtualizarCarregando(true)
		alertaDeInformacoesDoCliente = await aplicarTipoDeClienteVestcasa(venda, cpfOuCnpj, findClienteUseCase)
			.catch(() => aplicarTipoDeClienteAlmode(venda, cpfOuCnpj, isVestcasa, findClienteUseCase))
			.catch(() => aplicarTipoDeClienteNaoCadastrado(venda))
	} else {
		onAtualizarCarregando && onAtualizarCarregando(true)
		alertaDeInformacoesDoCliente = await aplicarTipoDeClienteAlmode(venda, cpfOuCnpj, isVestcasa, findClienteUseCase)
			.catch(() => aplicarTipoDeClienteNaoCadastrado(venda))
	}

	vendaModule.setInformacoesDoCliente(alertaDeInformacoesDoCliente)

	let itensAtualizados: ItemDaVenda[]
	if (tipoDeClienteAnterior === venda.tipoDeCliente || venda.tipoDeTransacao === 'Devolução') {
		itensAtualizados = venda.itens
	} else {
		onAtualizarCarregando && onAtualizarCarregando(true)
		itensAtualizados = await AplicarTabelaDePrecoUseCase({
			itens: venda.itens,
			loja,
			validarLimiteDeProduto: validarLimiteDeProduto,
			tipoDeCliente: venda.tipoDeCliente,
			vendaAtual: venda,
			descontarValor: 1,
		})
	}

	VendaModule.setVendaAtual({
		...venda,
		itens: itensAtualizados,
	})
}

async function aplicarTipoDeClienteVestcasa(
	venda: Venda,
	cpfDoCliente: string,
	findClienteUseCase: FindClienteUseCase,
): Promise<InformacoesDoCliente> {
	if (!isValidCPF(cpfDoCliente)) throw new Error('CPF inválido')
	const signature = await buscarNaApiClubeVestcasa(cpfDoCliente)
	if (!signature) throw new Error('Cliente não é clube vestcasa')

	try {
		const params = {
			gruposEconomicosId: VendaModule.lojaDaVenda ? [VendaModule.lojaDaVenda.grupoEconomico.id] : null,
		}
		const axiosConfig = {
			cancelToken: axios.CancelToken.source().token,
		}
		venda.cliente = await findClienteUseCase.get(cpfDoCliente, params, axiosConfig)
	} catch {
		venda.cliente = null
	}
	
	const expirou = verificarPlanoExpirado(signature.expire)
	venda.tipoDeCliente = expirou ? null : (venda.cliente?.tipoDeCliente || { nome: 'clube' } as TipoDeCliente)

	return obterAlertaDeInformacoesDoClienteClubeVestcasa(signature)
}

async function aplicarTipoDeClienteAlmode(
	venda: Venda,
	cnpjOuCpf: string,
	isVestcasa: boolean,
	findClienteUseCase: FindClienteUseCase,
): Promise<InformacoesDoCliente> {
	const params = {
		gruposEconomicosId: VendaModule.lojaDaVenda ? [VendaModule.lojaDaVenda.grupoEconomico.id] : null,
	}
	const axiosConfig = {
		cancelToken: axios.CancelToken.source().token,
	}
	const cliente = await findClienteUseCase.get(cnpjOuCpf, params, axiosConfig)
	if (!cliente) throw new Error('Cliente não encontrado')

	const expirou = verificarPlanoExpirado(cliente.dataDeExpiracaoDoPlano)
	venda.tipoDeCliente = expirou ? null : cliente.tipoDeCliente
	venda.cliente = cliente
	return obterAlertaDeInformacoesDoCliente(cliente, isVestcasa)
}

function aplicarTipoDeClienteNaoCadastrado(venda: Venda): InformacoesDoCliente {
	venda.tipoDeCliente = null
	venda.cliente = null
	return {
		mensagem: 'Cliente não existe no sistema',
		tipoDoAlerta: 'info',
	}
}

async function buscarNaApiClubeVestcasa(cnpjOuCpf: string ): Promise<Signature> {
	try {
		const { data } =  await Axios.get<SignatureResponse>(`https://apigateway.almode.com/clubevestcasa/v1/external/vestcasa/${cnpjOuCpf}`)
		return data.signature
	} catch(error) {
		if (!error?.isAxiosError) throw error
		if (error?.response.status === 412) return error.response.data.signature
		throw error
	}
}

function obterAlertaDeInformacoesDoClienteClubeVestcasa(signature: Signature): InformacoesDoCliente {
	const dataExpirou = verificarPlanoExpirado(signature.expire)
	return {
		mensagem: 'Cliente Clube. ' + obterMensagemDePlano(signature.expire),
		tipoDoAlerta: dataExpirou ? 'warning' : 'success',
	}
}

function obterAlertaDeInformacoesDoCliente(cliente: Cliente, isVestcasa: boolean): InformacoesDoCliente {
	if (isVestcasa && !cliente.tipoDeCliente) {
		return {
			mensagem: `${cliente.razaoSocialOuNome} não possui plano.`,
			tipoDoAlerta: 'info',
		}
	}
	
	if (!isVestcasa && !cliente.tipoDeCliente) {
		return {
			mensagem: `CLIENTE ${cliente.razaoSocialOuNome} .`,
			tipoDoAlerta: 'info',
		}
	}
	
	const dataExpirou = verificarPlanoExpirado(cliente.dataDeExpiracaoDoPlano);
	const tipoCliente = cliente.tipoDeCliente ? cliente.tipoDeCliente.nome : '';
	const mensagemPlano = obterMensagemDePlano(cliente.dataDeExpiracaoDoPlano);
	const mensagem = `${tipoCliente ? tipoCliente + ' ' : 'CLIENTE '}${cliente.razaoSocialOuNome}. ${mensagemPlano}`;

	return {
		mensagem,
		tipoDoAlerta: dataExpirou ? 'warning' : 'success',
	}
}

function obterMensagemDePlano(dataDeExpiracao: string | null) {
	if (!dataDeExpiracao) return ''
	const dataExpirou = verificarPlanoExpirado(dataDeExpiracao)
	return !dataExpirou
		? `Expira em ${formatDate(dataDeExpiracao)}`
		: `Expirado em ${formatDate(dataDeExpiracao)}`
}

function verificarPlanoExpirado(dataDeExpiracao: string | null) {
	if (!dataDeExpiracao) return false
	const hoje = moment().startOf('day')
	const expiracao = moment(dataDeExpiracao).startOf('day')
	return hoje.isAfter(expiracao)
}

export interface SignatureResponse {
	error: string
	signature: Signature
	statusCode: number
	success: true
}

export interface Signature {
	payment: string
	expire: string
}

export interface InformacoesDoCliente {
	tipoDoAlerta: 'info' | 'warning' | 'success'
	mensagem: string
}