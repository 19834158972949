
































































import { Vue, Component, Ref, Prop, Watch } from 'vue-property-decorator'
import { SituacaoDoPedidoDeVenda, VendaComPedido } from '@/models'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { PedidoServiceAdapter, VendaServiceAdapter } from '@/usecases'
import { Inject } from 'inversify-props'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'

@Component({
	components: {
	},
})
export default class DialogoDeEdicaoDePedido extends Vue {
	@Ref() form!: HTMLFormElement
	@Prop() subtitulo?: string
	@Prop() venda!: VendaComPedido

	@Inject('PedidoServiceAdapter')
	private pedidoService!: PedidoServiceAdapter

	@Inject('VendaServiceAdapter')
	private vendaService!: VendaServiceAdapter

	situacaoDoPedido: SituacaoDoPedidoDeVenda | null = null
	pendente: SituacaoDoPedidoDeVenda[] = ['Em Separação']
	em_separacao: SituacaoDoPedidoDeVenda[] = ['Pendente']
	faturado: SituacaoDoPedidoDeVenda[] = ['Despachado']
	despachado: SituacaoDoPedidoDeVenda[] = ['Entregue', 'Em Ocorrência']
	ocorrencia: SituacaoDoPedidoDeVenda[] = ['Entregue']

	mostra = false
	salvando = false

	situacoesAlteraveis: SituacaoDoPedidoDeVenda[] = [
		'Faturado',
		'Despachado',
		'Em Ocorrência',
	]

	get observacaoDoPedido() {
		return this.venda?.pedido.observacao
	}

	set observacaoDoPedido(observacao) {
		if (this.venda) this.venda.pedido.observacao = observacao || ''
	}

	get podeAlterarSituacao() {
		if (this.venda == null) {
			return false
		}
		return this.situacoesAlteraveis.includes(this.venda.pedido.situacao)
			&& this.venda.notas[0].cstat !== '101' || '102'
	}

	get opcoesDeSituacao() {
		if (this.venda == null) {
			return []
		}
		switch(this.venda.pedido.situacao) {
			case 'Pendente': return this.pendente;
			case 'Em Separação': return this.em_separacao;
			case 'Faturado': return this.faturado;
			case 'Despachado': return this.despachado;
			case 'Em Ocorrência': return this.ocorrencia;
		}
		return []
	}

	podeEditar() {
		if (
			this.venda.pedido.situacao !== 'Entregue' 
			&& this.venda.pedido.situacao !== 'Cancelado' 
			&& this.venda.pedido.situacao !== 'Devolvido'
		) {
			return true
		}
	}

	get podeModificarStatus(){
		return UserLoginStore.permiteRegraDeNegocio('pode-modificar-status')
	}

	async salvarPedido() {
		if (this.venda == null) {
			return
		}
		try {
			this.salvando = true
			if(this.venda.pedido && this.situacaoDoPedido !== null) this.venda.pedido.situacao = this.situacaoDoPedido

			if(this.venda.pedido !== null) {
				await this.pedidoService.atualizarSituacao(this.venda.pedido)
				this.mostra = false
				this.salvando = false
			}
		} catch (error) {
			AlertModule.setError(error)
		}finally{
			this.venda.pedido.observacao = ''
			this.$emit('input', this.venda)
		}
	}

	@Watch('mostra')
	async onChangeMostra() {
		if (this.venda?.pedido) {
			this.situacaoDoPedido = this.venda.pedido.situacao
		}
	}
}
