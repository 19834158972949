






















































import { Vue, Component, Ref, Prop, Watch } from 'vue-property-decorator'
import { SituacaoDoPedidoDeVenda, VendaComPedido } from '@/models'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { PedidoServiceAdapter } from '@/usecases'
import { Inject } from 'inversify-props'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'

@Component({
	components: {
	},
})
export default class DialogoDeEdicaoDePedido extends Vue {
	@Ref() form!: HTMLFormElement
	@Prop() subtitulo?: string
	@Prop() venda!: VendaComPedido

	@Inject('PedidoServiceAdapter')
	private pedidoService!: PedidoServiceAdapter

	situacaoDoPedido: SituacaoDoPedidoDeVenda | null = null
	listaDeSituacoesDoPedido: SituacaoDoPedidoDeVenda[] = ['Aguardando Coleta', 'Coletado']

	mostra = false
	salvando = false


	podeEditar() {
		if (
			this.venda.pedido.situacao !== 'Pendente' 
			&& this.venda.pedido.situacao !== 'Entregue' 
			&& this.venda.pedido.situacao !== 'Cancelado' 
			&& this.venda.pedido.situacao !== 'Devolvido'
		) {
			return true
		}
	}

	get podeModificarStatus(){
		return UserLoginStore.permiteRegraDeNegocio('pode-modificar-status')
	}

	async salvarPedido() {
		if (this.venda == null) {
			return
		}
		try {
			this.salvando = true
			if(this.venda.pedido && this.situacaoDoPedido !== null) this.venda.pedido.situacao = this.situacaoDoPedido

			if(this.venda.pedido !== null) {
				await this.pedidoService.atualizarSituacao(this.venda.pedido)
				this.mostra = false
				this.salvando = false
			}
		} catch (error) {
			AlertModule.setError(error)
		}finally{
			this.venda.pedido.observacao = ''
			this.$emit('input', this.venda)
		}
	}

	@Watch('mostra')
	async onChangeMostra() {
		if (this.venda?.pedido) {
			this.situacaoDoPedido = this.venda.pedido.situacao
		}
	}
}
