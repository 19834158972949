import { Inject } from 'inversify-props'
import { VendaServiceAdapter } from '../venda/adapter/index'
import { Venda } from '@/models'

export class CancelarNotaFiscalUseCase {
	@Inject('VendaServiceAdapter')
	private vendaService!: VendaServiceAdapter

	execute = async (id: string, motivoDoCancelamentoFiscal: string): Promise<Venda> => {
		return await this.vendaService.cancelarNotasFiscais(id, motivoDoCancelamentoFiscal)
	}
}
