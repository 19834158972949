var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.buscarPagina.apply(null, arguments)}}},[_c('v-container',{staticClass:"py-0",attrs:{"luid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":""}},[_c('SeletorDeLojasDoUsuario',{staticStyle:{"margin-left":"20px"},attrs:{"label":"Loja","prepend-icon":"mdi-store","clearable":"","disabled":_vm.buscando},model:{value:(_vm.filtro.lojaId),callback:function ($$v) {_vm.$set(_vm.filtro, "lojaId", $$v)},expression:"filtro.lojaId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":""}},[_c('RangeDatePicker',{attrs:{"label":"Data","loading":_vm.buscando,"disabled":_vm.buscando,"inicial":{
								label: 'Data Inicial',
								clearable: true,
							},"final":{
								label: 'Data Final',
								clearable: true,
							}},model:{value:(_vm.filtro.datas),callback:function ($$v) {_vm.$set(_vm.filtro, "datas", $$v)},expression:"filtro.datas"}})],1),_c('v-col',{staticClass:"d-flex align-center text-center",attrs:{"cols":"12","sm":"auto"}},[_c('v-btn',{attrs:{"color":"primary","width":"110","type":"submit"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-magnify ")]),_vm._v(" Aplicar ")],1)],1),_c('v-col',{staticClass:"d-flex align-center text-center",attrs:{"cols":"12","sm":"auto"}},[_c('v-btn',{attrs:{"width":"110","color":"normal","loading":_vm.buscando},on:{"click":_vm.abrirFiltros}},[_vm._v(" Filtros ")])],1)],1)],1)],1)],1),_c('CamposFiltroDePedidos',{ref:"dialogoDeFiltros",attrs:{"eager":""},on:{"limpar":_vm.limparSelecaoDeFiltros,"buscar":_vm.buscarPagina},model:{value:(_vm.filtro),callback:function ($$v) {_vm.filtro=$$v},expression:"filtro"}}),(!_vm.mostrarTabela)?_c('div',{staticClass:"text-center mt-3"},[_vm._v(" Use os filtros para visualizar os pedidos ")]):_vm._e(),(_vm.mostrarTabela)?_c('v-card',{staticClass:"mt-2"},[_c('v-data-table',{attrs:{"hideCreate":"","items":_vm.itensFormatados,"headers":_vm.headers,"loading":_vm.buscando,"sort-by":"dataHora","sort-desc":[true],"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.informacoesDeTransporte",fn:function(ref){
							var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-icon',_vm._g({staticClass:"ml-1",attrs:{"color":item.informacoesDeTransporte.modalidadeDeFrete !==
									'Sem Frete'
										? 'success'
										: '#89CFF0'}},on),[_vm._v(" "+_vm._s(item.informacoesDeTransporte.modalidadeDeFrete !== 'Sem Frete' ? 'mdi-truck-check' : 'mdi-store')+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.informacoesDeTransporte.modalidadeDeFrete !== 'Sem Frete' ? 'Possui transportadora' : 'Retirada local'))])])]}},{key:"item.displayNfSerie",fn:function(ref){
										var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-nowrap"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"300","disabled":!item.notas[0]},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
										var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-flex justify-end"},on),[(item.notas[0])?_c('v-icon',{attrs:{"color":item.notas[0].ambiente === 'Homologação' ?'grey': 'green accent-4'}},[_vm._v(" mdi-circle-medium ")]):_vm._e(),_c('div',{staticClass:"text-no-wrap pr-1",class:{ 'text-decoration-line-through': item.notas.length && item.notas[0].cstat === '101' }},[_vm._v(" "+_vm._s(item.displayNfSerie)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.notas[0] && item.notas[0].ambiente === 'Homologação' ?'Homologação': 'Produção'))])]),(item.notas.length > 1)?_c('v-menu',{attrs:{"offset-x":"","open-on-hover":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
										var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"primary"}},on),[_vm._v(" mdi-dots-horizontal ")])]}}],null,true)},[_c('v-card',_vm._l((item.notas.slice(1)),function(nota,indice){return _c('v-list-item',{key:indice},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(nota.nnf)+"/"+_vm._s(nota.serie)+" - "+_vm._s(nota.modelo)+" ")]),(nota.cstat.length)?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(nota.cstat)+" - "+_vm._s(_vm.mapErrosSefaz[nota.cstat])+" ")]):_vm._e()],1)],1)}),1)],1):_vm._e()],1)]}},{key:"item.actions",fn:function(ref){
										var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-nowrap justify-center"},[_c('DialogoDeDetalhesDaVenda',{attrs:{"venda":item,"identificadorExterno2":"true"},on:{"input":function (vendaAtualizada) { return _vm.atualizarStatusDoPedido(vendaAtualizada); },"atualizarTotalDoPedido":function (vendaAtualizada) { return _vm.atualizarTotalDoPedido(vendaAtualizada); },"cancelarVenda":function (vendaAhCancelar, motivo) { return _vm.cancelarVenda(vendaAhCancelar, motivo); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
										var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
										var tooltip = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""}},Object.assign({}, tooltip, dialog)),[_vm._v(" mdi-eye ")])]}}],null,true)},[_vm._v(" Detalhes do Pedido ")])]}}],null,true)}),(item.situacaoPedido === 'Pendente')?_c('DialogoDeVerificacaoDeProdutos',{ref:"dialogoDeVerificacaoDeProdutos",attrs:{"venda":item},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
										var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
										var tooltip = ref.on;
return [(_vm.deveVerificarProdutos === true)?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""}},Object.assign({}, tooltip, dialog)),[_vm._v(" mdi-tag ")]):_vm._e()]}}],null,true)},[_vm._v(" Verificar Produtos ")])]}}],null,true)}):_vm._e(),(_vm.podeCancelarPedido && item.situacaoPedido !== 'Devolvido')?_c('ConfirmacaoComMotivo',{attrs:{"width":"500","titulo":"Cancelar pedido?","loja":item.pontoDeVenda.loja.id},on:{"confirmar":function (motivo) { return _vm.cancelarVenda(item, motivo); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
										var dialog = ref.on;
return [(!item.temDevolucao && item.situacao !== 'Cancelada')?_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
										var tooltip = ref.on;
return [(item.situacao !== 'Devolvido')?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","disabled":_vm.cancelandoPedidos.includes(item.id)}},Object.assign({}, tooltip, dialog)),[_vm._v(" "+_vm._s(_vm.cancelandoPedidos.includes(item.id) ? 'mdi-loading mdi-spin' : 'mdi-briefcase-remove')+" ")]):_vm._e()]}}],null,true)},[_vm._v(" Cancelar Pedido ")]):_vm._e()]}}],null,true)}):_vm._e(),(_vm.podeCancelarPedido && item.notas.length)?_c('ConfirmacaoComMotivoFiscal',{attrs:{"width":"300","titulo":"Cancelar nota(s)?","subtitulo":("<div>" + (_vm.displayNotasFiscais(item)) + "</div>")},on:{"confirmar":function (motivo) { return _vm.cancelarNotasFiscais(item, motivo); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
										var dialog = ref.on;
return [(
									!item.temDevolucao &&
										_vm.podeCancelar(item.notas) &&
										item.tipoDeTransacao !== 'Devolução'
								)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
								var tooltip = ref.on;
return [(_vm.validaSituacao(item))?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""}},Object.assign({}, tooltip, dialog)),[_vm._v(" "+_vm._s(_vm.cancelandoNotasFiscais.includes(item.id) ? 'mdi-loading mdi-spin' : 'mdi-text-box-remove')+" ")]):_vm._e()]}}],null,true)},[_vm._v(" Cancelar Nota ")]):_vm._e()]}}],null,true)}):_vm._e(),_c('Confirmacao',{attrs:{"width":"300","titulo":"Devolver pedido?","type":"submit","subtitulo":"<div>" + ('Identificador: ' + item.identificador) + "</div>" +
								"<div>" + ('Situação atual: ' + _vm.displaySituacao(item)) + "</div>"},on:{"confirmar":function () { return _vm.devolverPedido(item); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
								var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
								var tooltip = ref.on;
return [(
											item.situacaoPedido !== 'Devolvido' &&
												item.situacaoPedido !== 'Cancelado' &&
												item.tipoDeTransacao !== 'Devolução' && 
												_vm.podeDevolverPedido()
										)?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""}},Object.assign({}, tooltip, dialog)),[_vm._v(" "+_vm._s(_vm.iniciandoDevolucao ? 'mdi-loading mdi-spin' : 'mdi-archive-arrow-up')+" ")]):_vm._e()]}}],null,true)},[_vm._v(" Devolver Pedido ")])]}}],null,true)}),_c('DialogoDeEdicaoDeColeta',{attrs:{"venda":item,"subtitulo":"<div>" + ('Identificador: ' + item.identificador) + "</div>" +
								"<div>" + ('Situação atual: ' + _vm.displaySituacao(item)) + "</div>"},on:{"input":function (vendaAtualizada) { return _vm.atualizarStatusDoPedido(vendaAtualizada); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
								var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
								var tooltip = ref.on;
return [(item.situacaoPedido === 'Aguardando Coleta')?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""}},Object.assign({}, tooltip, dialog)),[_vm._v(" mdi-truck-check ")]):_vm._e(),(item.situacaoPedido === 'Coletado')?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","color":"success"}},Object.assign({}, tooltip, dialog)),[_vm._v(" mdi-truck-check ")]):_vm._e()]}}],null,true)},[_vm._v(" Editar Coleta ")])]}}],null,true)}),_c('DialogoDeEdicaoDePedido',{attrs:{"venda":item,"subtitulo":"<div>" + ('Identificador: ' + item.identificador) + "</div>" +
								"<div>" + ('Situação atual: ' + _vm.displaySituacao(item)) + "</div>"},on:{"input":function (vendaAtualizada) { return _vm.atualizarStatusDoPedido(vendaAtualizada); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
								var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
								var tooltip = ref.on;
return [(item.situacaoPedido !== 'Aguardando Coleta' && item.situacaoPedido !=='Coletado')?_c('v-icon',_vm._g({attrs:{"small":""}},Object.assign({}, tooltip, dialog)),[_vm._v(" mdi-pencil > mdi-pencil ")]):_vm._e()]}}],null,true)},[_vm._v(" Editar Situação ")])]}}],null,true)})],1)]}},{key:"footer",fn:function(){return [_c('RodapePersonalizado',{attrs:{"paginacao":_vm.paginacao,"total":_vm.itensFormatados.length,"loading":_vm.buscando,"itens-per-page-options":[5, 10, 15, 30, 50]},on:{"navegar":_vm.atualizarPagina}})]},proxy:true}],null,true)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }